<template>
  <nav class="navbar navbar-expand-xl navbar-light shadow-sm bg-white">
    <router-link to="/" exact class="title">
      <img src="@/assets/Group 1.png" />
    </router-link>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarNavDropdown"
      aria-controls="navbarNavDropdown"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <ul class="nav navbar-nav ml-auto">
        <li class="nav-item">
          <router-link to="/about" class="nav-link">O stronie</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/blog" class="nav-link">Blog</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/ebook" class="nav-link">E-book</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/sheet" class="nav-link">Aplikacja</router-link>
        </li>
        <li class="nav-item">
          <router-link to="/other" class="nav-link">Inne usługi</router-link>
        </li>

        <li v-if="!currentUser" class="nav-item">
          <router-link to="/login" class="nav-link">Zaloguj się </router-link>
        </li>
        <li v-if="currentUser" class="nav-item">
          <router-link to="/profile" class="nav-link">Profil </router-link>
        </li>
        <li v-if="currentUser" class="nav-item">
          <a href @click.prevent="logOut" class="nav-link">Wyloguj się </a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/");
    }
  }
};
</script>

<style scoped>
.navbar-expand-xl {
  padding: 0 30px;
}
.nav-link {
  margin-right: 40px;
  color: black !important;
  font-weight: bold;
  font-style: italic;
}
.title {
  margin-left: 0px;
}
nav {
  z-index: 9999;
  padding-top: 7px;
  padding-bottom: 0;
}
.nav-link {
  border-bottom: 3px solid transparent;
}

@media (max-width: 1199px) {
  .nav-item .router-link-active {
    border-left: 12px solid #ffff00;
    padding-left: 7px;
  }
}
@media (min-width: 1200px) {
  .nav-item .router-link-active {
    border-bottom: 3px solid #ffff00;
  }
  .title {
    margin-left: 30px;
  }
}
@media (min-width: 1368px) {
  .title {
    margin-left: 70px;
  }
}
</style>
