import Vue from "vue";
import App from "./layouts/App.vue";
import { router } from "./router";
import store from "./store";

import VeeValidate from "vee-validate";

Vue.config.productionTip = false;

Vue.use(VeeValidate);
require('dotenv').config()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
