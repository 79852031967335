import axios from "axios";
const instance = axios.create();
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      error &&
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      localStorage.removeItem("user");
      location.href = "https://www.przeplywy.pl/login";
    } else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  }
);

export default instance;
