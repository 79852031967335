import Vue from "vue";
import Vuex from "vuex";

import { auth } from "./auth.module";
import { user } from "./user.module";
import cookieConsent from "./CookieConsent/cookieConsent";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    user,
    cookieConsent
  }
});
