import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Login from "./views/Login.vue";
import SuccessRegister from "./views/SuccessRegister.vue";

Vue.use(Router);

export const router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home
    },
    {
      path: "/login",
      component: Login
    },
    {
      path: "/success-register",
      component: SuccessRegister
    },
    {
      path: "/profile",
      name: "profile",
      component: () => import("./views/Profile.vue")
    },
    {
      path: "/about",
      name: "about",
      component: () => import("./views/About.vue")
    },
    {
      path: "/blog",
      name: "blog",
      component: () => import("./views/Blog.vue")
    },
    {
      path: "/blog/:id",
      name: "blogPost",
      component: () => import("./views/BlogPost.vue")
    },
    {
      path: "/ebook",
      name: "ebook",
      component: () => import("./views/Ebook.vue")
    },
    {
      path: "/other",
      name: "other",
      component: () => import("./views/Other.vue")
    },
    {
      path: "/sheet",
      name: "sheet",
      component: () => import("./views/Sheet.vue")
    },
    {
      path: "/payment",
      name: "payment",
      component: () => import("./views/Payment.vue")
    },
    {
      path: "/polityka",
      name: "polityka",
      component: () => import("./views/Policy.vue")
    },
    {
      path: "/regulamin",
      name: "regulamin",
      component: () => import("./views/Regulations.vue")
    }
  ]
});

// router.beforeEach((to, from, next) => {});
