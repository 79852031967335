<template>
  <div id="app">
    <div>
      <Header v-if="cookies"></Header>
      <Page></Page>
    </div>
  </div>
</template>

<script>
import Header from "./Header";
import Page from "./Page";
export default {
  components: {
    Header,
    Page
  },
  data() {
    return {
      color: "white",
      isVisible: ""
    };
  },
  created() {
    this.setCookies();
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    cookies() {
      return this.$store.getters.getCookieConsent;
    }
  },
  methods: {
    setCookies() {
      if (localStorage.getItem("cookiesAccepted") === "yes") {
        this.$store.dispatch("setCookies", true);
      } else {
        this.$store.dispatch("setCookies", false);
      }
    },
    logOut() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    closeCookieConsent() {
      this.showCookie = false;
    }
  }
};
</script>

<style lang="scss">
@import "../assets/css/main.css";
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 300;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 800 !important;
  font-style: italic;
  font-family: "Oswald", sans-serif;
}
strong {
  font-weight: 700;
}
#container {
  max-width: 1400px;
  margin: 0 auto;
}

#app {
  height: 100vh;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
