import axios from "@/axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_URL + "payments/";// "https://przeplywy.pl/api/payments/"; //"http://77.55.215.133:8181/api/payments/";
const P24_URL = "https://secure.przelewy24.pl/";////"https://sandbox.przelewy24.pl/";//
const RETURN_URL = "https://przeplywy.pl/sheet"; //"http://rachunekprzeplywow.pl/sheet";
const CANCEL_URL = "https://przeplywy.pl/payment"; //"http://rachunekprzeplywow.pl/payment";

class PaymentService {
  initPayment(data, email) {
    const form = document.createElement("form");
    form.method = "post";
    form.action = P24_URL + "trnDirect";

    var hiddenField = document.createElement("input");
    hiddenField.type = "hidden";
    hiddenField.name = "p24_merchant_id";
    hiddenField.value = data["p24_merchant_id"];
    form.appendChild(hiddenField);

    hiddenField = document.createElement("input");
    hiddenField.type = "hidden";
    hiddenField.name = "p24_pos_id";
    hiddenField.value = data["p24_merchant_id"];
    form.appendChild(hiddenField);

    hiddenField = document.createElement("input");
    hiddenField.type = "hidden";
    hiddenField.name = "p24_session_id";
    hiddenField.value = data["p24_session_id"];
    form.appendChild(hiddenField);

    hiddenField = document.createElement("input");
    hiddenField.type = "hidden";
    hiddenField.name = "p24_amount";
    hiddenField.value = data["p24_amount"];
    form.appendChild(hiddenField);

    hiddenField = document.createElement("input");
    hiddenField.type = "hidden";
    hiddenField.name = "p24_currency";
    hiddenField.value = data["p24_currency"];
    form.appendChild(hiddenField);

    hiddenField = document.createElement("input");
    hiddenField.type = "hidden";
    hiddenField.name = "p24_email";
    hiddenField.value = email;
    form.appendChild(hiddenField);

    hiddenField = document.createElement("input");
    hiddenField.type = "hidden";
    hiddenField.name = "p24_country";
    hiddenField.value = "PL";
    form.appendChild(hiddenField);

    hiddenField = document.createElement("input");
    hiddenField.type = "hidden";
    hiddenField.name = "p24_url_return";
    hiddenField.value = RETURN_URL;
    form.appendChild(hiddenField);

    hiddenField = document.createElement("input");
    hiddenField.type = "hidden";
    hiddenField.name = "p24_url_cancel";
    hiddenField.value = CANCEL_URL;
    form.appendChild(hiddenField);

    hiddenField = document.createElement("input");
    hiddenField.type = "hidden";
    hiddenField.name = "p24_url_status";
    hiddenField.value = data["url"];
    form.appendChild(hiddenField);

    hiddenField = document.createElement("input");
    hiddenField.type = "hidden";
    hiddenField.name = "p24_api_version";
    hiddenField.value = "3.2";
    form.appendChild(hiddenField);

    hiddenField = document.createElement("input");
    hiddenField.type = "hidden";
    hiddenField.name = "p24_sign";
    hiddenField.value = data["p24_sign"];
    form.appendChild(hiddenField);

    document.body.appendChild(form);
    return form.submit();
  }

  getPaymentStatus() {
    return axios.get(API_URL + "paymentStatus", { headers: authHeader() });
  }

  getPaymentData() {
    return axios.get(API_URL + "init", { headers: authHeader() });
  }
}

export default new PaymentService();
