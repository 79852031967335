const state = {
  cookiesAccepted: false
};

const getters = {
  getCookieConsent: state => {
    return state.cookiesAccepted;
  }
};
const mutations = {
  SET_COOKIE_CONSENT(state, res) {
    state.cookiesAccepted = res;
  }
};

const actions = {
  setCookies({ commit }, value) {
    commit("SET_COOKIE_CONSENT", value);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
