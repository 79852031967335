<template>
  <div class="container-fluid">
    <div class="row no-gutters shadow" :style="blurStyle()">
      <div class="col-lg-6">
        <img src="../../src/assets/Mask Group 1.png" class="img-col" />
      </div>

      <div class="col-lg-6 text-col">
        <div class="text-left sectionTop">
          <h1 class="title text-center">
            Jak prawidłowo <br />sporządzić Rachunek <br />przepływów
            pieniężnych?
          </h1>
          <h6 class="sectionTop title  text-center">
            <span class="strike-20">Zakończenie</span
            ><span class="strike-20">roku</span>
          </h6>
          <div style="margin-bottom:30px;" class=" text-center">
            <strong>Bilanse, Wyniki i Przepływy Finansowe?</strong>
          </div>
          <div class="sectionBottom text-center">
            Pomogę Ci zrobić to szybko, sprawnie i prawidłowo!
          </div>
          <div class="sectionBottom  text-center">
            Przedstawiam aplikację pomagającą
            <span class="strike-20">skutecznie</span>
            <span class="strike-20">sporządzić</span>
            <span class="strike-20">Rachunek</span>
            <span class="strike-20">przepływów</span
            ><span class="strike-20 last-char">pieniężnych.</span>
          </div>
          <div class=" text-center">
            Jeśli <span class="strike-20">pracujesz</span
            ><span class="strike-20">w</span
            ><span class="strike-20">księgowości</span> i zdarzają Ci się
            sytuacje, kiedy <span class="strike-20">nie</span
            ><span class="strike-20">wiesz</span>
            <span class="strike-20">jak</span
            ><span class="strike-20">sporządzić</span
            ><span class="strike-20">rachunek</span
            ><span class="strike-20">przepływów</span
            ><span class="strike-20">pieniężnych,</span>sytuacje po których w
            domu zamiast wypocząć siadasz z laptopem aby zakończyć to zdanie?
          </div>
          <h6 class="sectionTop  text-center">
            <span class="strike-20">Ta</span
            ><span class="strike-20">strona</span
            ><span class="strike-20">jest</span
            ><span class="strike-20">dla</span
            ><span class="strike-20 last-char">Ciebie!</span>
          </h6>
          <div class="wrap ">
          <router-link to="/about">
          
         
              <button class="mt-5 btn btn-yellow btn-center">
              <span class="btn-yellow-inner">O Stronie</span>
            </button>
     
      
          </router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="container" v-if="isVisible">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container">
              <img class="modal-logo" src="@/assets/Group 1.png" />
              <h2 class="text-center text-uppercase">Klauzula informacyjna</h2>
              <div>
                <p>
                  Zgodnie z art. 13 Rozporządzenia Parlamentu Europejskiego i
                  Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie
                  ochrony osób fizycznych w związku z przetwarzaniem danych
                  osobowych i w sprawie swobodnego przepływu takich danych oraz
                  uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie
                  danych) (Dz.U.UE.L.2016.119.1 z dnia 2016.05.04; dalej:
                  „RODO”) informuję, iż:
                </p>
                <ul>
                  <li>
                    administratorem Pani/Pana danych osobowych jest Piotr
                    Martin, ul. Kapelanka 6a/162, 30-347 Kraków;
                  </li>
                  <li>
                    Pani/Pana dane osobowe przetwarzane będą w celu realizacji
                    umowy – na podstawie art. 6 ust. 1 lit. b) RODO, w celu
                    wykonania obowiązku nałożonego prawem na administratora w
                    postaci prowadzenia dokumentacji oraz ewidencji wpłat – na
                    podstawie art. 6 ust. 1 lit. c) RODO, w celach
                    statystycznych, personalizacyjnych, analityki internetowej
                    oraz dostosowania serwisu przeplywy.pl do potrzeb
                    użytkowników – na podstawie art. 6 ust. 1 lit. a) RODO, a
                    także w celu analityki oraz planowania biznesowego na
                    podstawie art. 6 ust. 1 lit. f) RODO (prawnie
                    usprawiedliwiony interes administratora);
                  </li>
                  <li>
                    odbiorcami Pani/Pana danych osobowych będą wyłącznie
                    podmioty uprawnione do uzyskania danych osobowych oraz
                    podmioty uczestniczące w realizacji usługi, w tym podmioty
                    świadczące usługi informatyczne i biuro rachunkowe
                    obsługujące administratora;
                  </li>
                  <li>
                    posiada Pani/Pan prawo do żądania od administratora dostępu
                    do danych osobowych, ich sprostowania, usunięcia lub
                    ograniczenia przetwarzania. Posiada Pani/Pan prawo
                    wniesienia sprzeciwu wobec przetwarzania Pani/Pana danych
                    osobowych, a także prawo do przeniesienia danych osobowych.
                    W celu realizacji tych praw odpowiedni wniosek powinien
                    zostać przesłany na adres mailowy administratora:
                    biuro@przeplywy.pl;<br />
                    • w przypadku stwierdzenia naruszeń ochrony Pani/Pana danych
                    osobowych, ma Pani/Pan prawo wniesienia skargi do organu
                    nadzorczego;
                  </li>
                  <li>
                    w odniesieniu do danych osobowych zbieranych na podstawie
                    zgody, posiada Pani/Pan prawo do cofnięcia zgody w każdym
                    momencie. Cofnięcie to nie ma wpływu na legalność
                    przetwarzania danych, objętych zgodą przed jej cofnięciem;
                  </li>
                  <li>
                    podanie danych osobowych jest dobrowolne, jednakże odmowa
                    podania danych może skutkować odmową zawarcia umowy i
                    wykonania usługi. Zbieranie danych osobowych uzasadnione
                    jest zawieraną umową.
                  </li>
                </ul>

                <br />
                <h4 class="text-center text-uppercase">
                  zgoda na wykorzystanie plików cookies
                </h4>
                <br />
                <p>
                  Działając na podstawie art. 6 ust. 1 lit. a) rozporządzenia
                  Parlamentu Europejskiego i Rady (UE) w sprawie ochrony osób
                  fizycznych w związku z przetwarzaniem danych osobowych i w
                  sprawie swobodnego przepływu takich danych oraz uchylenia
                  dyrektywy 95/46/WE (ogólne rozporządzenie o chronie danych;
                  dalej: 'RODO') wyrażam zgodę na przetwarzanie przez
                  administratora – Piotr Martin z siedzibą w Krakowie, ul.
                  Kapelanka 6a/152, 30-347 Kraków, moich danych osobowych w
                  postaci historii odwiedzanych przeze mnie stron, mojej
                  aktywności w Internecie poprzez wykorzystywanie plików cookies
                  na stronie www.przeplywy.pl. Niniejsza zgoda obejmuje
                  przetwarzanie tych danych na cele statystyczne,
                  personalizacyjne, analityki Internetowej, a także dostosowania
                  serwisu do potrzeb użytkowników. Niniejsza zgoda wyrażona jest
                  w sposób świadomy i dobrowolny.
                </p>
                <br />
                <p>
                  Oświadczam, że jest mi znana klauzula informacyjna,
                  dostarczona mi przez administratora zgodnie z art. 13 RODO. W
                  szczególności zostałem/-am poinformowany/-a o możliwości
                  cofnięcia zgody w każdym momencie. Do cofnięcia może dojść w
                  formie, w jakiej wyrażono zgodę. Posiadam wiedzę, że
                  niewyrażenie lub cofnięcie zgody nie pociągnie za sobą żadnych
                  negatywnych konsekwencji. Oświadczam, że ukończyłem 16 lat.
                  Oświadczam, że mam świadomość, że w przypadku chęci wyrażenia
                  zgody na wykorzystanie plików cookies wyłączenie w
                  poszczególnych celach powyżej wskazanych, mogę to uczynić
                  klikając na link1 oraz wybierając odpowiednie ustawienia.
                  Dalsze infromacje o możliwości dokonywania zmian, dostępne są
                  pod tym linkiem2.
                </p>
              </div>
              <button class="btn btn-yellow" @click="setConsent()">
                <span class="btn-yellow-inner">akceptuję</span>
              </button>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
// import UserService from '../services/user.service';

export default {
  name: "Home",
  created() {
    this.mapToData();
  },
  computed: {
    cookies() {
      return this.$store.getters.getCookieConsent;
    },
  },
  data() {
    return {
      isVisible: true,
    };
  },
  methods: {
    mapToData() {
      if (localStorage.getItem("cookiesAccepted") === "yes") {
        this.isVisible = false;
      } else {
        this.isVisible = true;
      }
    },
    setConsent() {
      localStorage.setItem("cookiesAccepted", "yes");
      this.$store.dispatch("setCookies", true);
      this.isVisible = false;
    },
    blurStyle() {
      if (this.isVisible) {
        return "filter: blur(8px);";
      } else {
        return "";
      }
    },
  },
};
</script>
<style scoped>
.text-container {
  padding: 40px 70px;
}
.container-fluid {
  padding-right: 0px;
  padding-left: 0px;
  padding-bottom: 0px;
}
.title {
  font-weight: bold;
  font-style: italic;
}
.text-container {
  margin: 0 auto;
}

.img-col {
  position: fixed;
  left: 0;
  top: 0;
  object-fit: cover;
  width: 50%;
  height: 100vh;
  z-index: -1;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
  transition: opacity 0.3s ease;
}
.sectionBottom {
  margin-bottom: 40px;
}

.text-center{
  text-align: center;
}
.sectionTop {
  margin-top: 50px;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 80vw;
  margin: 0px auto;
  padding: 15px 20px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80vh;
  word-break: inherit;
  overflow-y: auto;
}
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.modal-container p {
  padding: 0 20px;
}
.modal-container h2 {
  margin: 20px 0;
}
.modal-container ul {
  padding: 0px 20px 0px 40px;
}
.modal-container ul li {
  margin-bottom: 12px;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.wrap{
display: flex;
align-items: center;
justify-content: center;
height: 100%;
width:100%;
}
.btn-center{
  align-self: center;
  justify-self: center;
}

.modal-body {
  margin: 20px 0;
}
.modal-logo {
  width: 150px;
  margin-bottom: 10px;
  object-fit: contain;
}

.modal-default-button {
  float: right;
}
.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}
.text-col {
  min-height: Calc(100vh - 47px);
  padding: 30px 120px;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
@media (max-width: 992px) {
  .img-col {
    width: 100%;
    opacity: 0.4;
  }
}
@media (max-width: 767px) {
  div,
  h6 {
    font-size: 1rem;
  } /*1rem = 16px*/
  h1 {
    font-size: 1.4rem;
  }
  .sectionBottom {
    margin-bottom: 10px;
  }
  .sectionTop {
    margin-top: 20px;
  }
  .text-col {
    min-height: Calc(100vh - 47px);
    padding: 30px 40px;
  }
}
/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
  div,
  h6 {
    font-size: 1.4rem;
  } /*1rem = 16px*/
  h1 {
    font-size: 1.6rem;
  }
  .sectionBottom {
    margin-bottom: 10px;
  }
  .sectionTop {
    margin-top: 20px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  div,
  h6 {
    font-size: 1rem;
  } /*1rem = 16px*/
  h1 {
    font-size: 1.4rem;
  }
  .sectionBottom {
    margin-bottom: 20px;
  }
  .sectionTop {
    margin-top: 30px;
  }
  .modal-container {
    width: 70vw;
    padding: 20px 50px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  div,
  h6 {
    font-size: 1.1rem;
  }
  h1 {
    font-size: 1.6rem;
  }
  .sectionBottom {
    margin-bottom: 30px;
  }
  .sectionTop {
    margin-top: 40px;
  }
}
@media (min-width: 1400px) {
  div,
  h6 {
    font-size: 1.1rem;
  } /*1rem = 16px*/
  h1 {
    font-size: 2.4rem;
  }
}
</style>
