import axios from "@/axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_URL; //"http://localhost:8181/api/";//"https://przeplywy.pl/api/"; //"http://77.55.215.133:8181/api/";

class UserService {
  getSendEmailEbook(email) {
    var url = API_URL + "email/send/" + email;
    url = encodeURI(url);
    return axios.get(url);
  }

  updateUser(user) {
    return axios.put(API_URL + "user", user, {
      headers: authHeader(),
    });
  }
  updateUserPassword(obj) {
    return axios.put(API_URL + "user/changePassword", obj, {
      headers: authHeader(),
    });
  }

  getSendEmailHelp(firstName, lastName, email, phone, message) {
    var url =
      API_URL +
      "email/help/" +
      firstName +
      "&" +
      lastName +
      "&" +
      email +
      "&" +
      phone +
      "&" +
      message;
    url = encodeURI(url);
    return axios.get(url);
  }

  getNewSheet(type) {
    return axios.get(API_URL + "sheets/getSheetByType/" + type, {
      headers: authHeader(),
    });
  }

  updateSheet(sheet) {
    return axios.post(API_URL + "sheets/updateSheet", sheet, {
      headers: authHeader(),
    });
  }

  deleteSheet(id) {
    return axios.delete(API_URL + "sheets/deleteSheet/" + id, {
      headers: authHeader(),
    });
  }

  downloadSheet(id) {
    return axios.get(API_URL + "sheets/download/" + id + "/sheet.xlsx", {
      responseType: "blob",
      headers: authHeader(),
    });
  }

  getUserSheets() {
    return axios.get(API_URL + "sheets/getAllBasicSheetsForCurrentUser", {
      headers: authHeader(),
    });
  }

  getSheet(id) {
    return axios.get(API_URL + "sheets/getSheet/" + id, {
      headers: authHeader(),
    });
  }

  getUser() {
    return axios.get(API_URL + "user", {
      headers: authHeader(),
    });
  }
  getUsersSubscriptionExpiryDate() {
    return axios.get(API_URL + "user/subscription/expiryDate", {
      headers: authHeader(),
    });
  }

  // getPublicContent() {
  //     return axios.get(API_URL + 'all');
  // }

  // getUserBoard() {
  //     return axios.get(API_URL + 'user', { headers: authHeader() });
  // }

  // getModeratorBoard() {
  //     return axios.get(API_URL + 'mod', { headers: authHeader() });
  // }

  // getAdminBoard() {
  //     return axios.get(API_URL + 'admin', { headers: authHeader() });
  // }
}

export default new UserService();
